import Web3 from 'web3';

let web3;

if (window.ethereum) {
    web3 = new Web3(window.ethereum);
} else if (window.web3) {
    web3 = new Web3(window.web3.currentProvider);
} else {
    console.error('No web3 provider detected. Falling back to Infura...');
    web3 = new Web3(new Web3.providers.HttpProvider('https://mainnet.infura.io/v3/17d2569ebc74480389778d81864c4149'));
}

export default web3;
