import React, { useState, useEffect, useCallback, useRef } from 'react';
import './App.css';
import web3 from './ethereum/web3';
import proNFT from './ethereum/proNFT';
import vipNFT from './ethereum/vipNFT';
import dudesRaffle from './ethereum/dudesRaffle';
import * as s from "./styles/globalStyles";
import styled from "styled-components";


export const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: var(--bg-color);
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;


export const ActionContainer = styled.div`
  display: flex;
  gap: 10px; // This provides a gap between the buttons.
  justify-content: center;
  align-items: center;
  margin-top: 20px;
`;


export const Notification = styled.div`
  padding: 10px;
  border-radius: 5px;
  background-color: ${props => props.type === 'success' ? 'green' : 'red'}; 
  color: var(--accent-text);
  text-align: center;
  font-weight: light;
`;


export const EthereumAddressInput = styled.input`
  width: 330px; // adjust to your desired width for Ethereum address
  padding: 6px;
  border: 1px solid var(--border-color);
  border-radius: 5px;
  margin-bottom: 0px;
`;


export const EpochNumberInput = styled.input`
  width: 45px; // adjust to your desired width for Epoch number
  padding: 6px;
  border: 1px solid var(--border-color);
  border-radius: 5px;
  margin-bottom: 0px;
`;


export const TicketsInput = styled.input`
  width: 45px; // adjust to your desired width for Number of tickets
  padding: 6px;
  border: 1px solid var(--border-color);
  border-radius: 5px;
  margin-bottom: 0px;
`;


export const StyledButton = styled.button`
  padding: 10px;
  border-radius: 7px;
  border: 2px solid var(--border-color);
  background-color: var(--btnbg);
  font-weight: bold;
  color: var(--accent-text);
  width: 100px;
  cursor: pointer;
    :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;


export const StyledButton2 = styled.button`
  padding: 6px;
  border-radius: 7px;
  border: 2px solid var(--updown);
  background-color: #8d108b; 
  font-weight: bold;
  color: var(--accent-text);
  width: 40px;
  cursor: pointer;
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;



export const StyledRoundButton = styled.button`
  padding: 10px;
  border-radius: 100%;
  border: none;
  background-color: var(--primary);
  padding: 10px;
  font-weight: bold;
  font-size: 15px;
  color: var(--primary-text);
  width: 30px;
  height: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;


export const ResponsiveWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: stretched;
  align-items: stretched;
  width: 100%;
  @media (min-width: 767px) {
    flex-direction: row;
  }
`;


export const StyledLogo = styled.img`
  width: 200px;
  @media (min-width: 767px) {
    width: 300px;
  }
  transition: width 0.5s;
  transition: height 0.5s;
`;


export const StyledImg = styled.img`
  box-shadow: 0px 5px 11px 2px rgba(0, 0, 0, 0.7);
  background-color: var(--accent);
  border-radius: 100%;
  width: 200px;
  @media (min-width: 900px) {
    width: 250px;
  }
  @media (min-width: 1000px) {
    width: 300px;
  }
  transition: width 0.5s;
`;


export const StyledLink = styled.a`
  color: var(--indigo);
  text-decoration: none;
`;


// App Starts


function App() {
  const [hasWeb3Provider, setHasWeb3Provider] = useState(null);
  const [account, setAccount] = useState('');
  const [ownsNFT, setOwnsNFT] = useState(false);
  const [currentEpoch, setCurrentEpoch] = useState(0);
  // const [countdown, setCountdown] = useState({ days: 0, hours: 0, minutes: 0, seconds: 0 }); //
  // const [isCountdownEnded, setIsCountdownEnded] = useState(false); //
  const [ticketPrice, setTicketPrice] = useState('');
  const [ticketsForThisRaffle, setTicketsForThisRaffle] = useState(0);
  const [isConnected, setIsConnected] = useState(false);
  const [maxTickets, setMaxTickets] = useState(0);
  const [numTicketsToBuy, setNumTicketsToBuy] = useState(1);
  const [epochInput, setEpochInput] = useState(1); // input for epoch
  const [winners, setWinners] = useState([]); // winners for a specific epoch
  const [addressInput, setAddressInput] = useState("");
  const [notification, setNotification] = useState({ message: '', type: '' });
  const [triggerTimeout, setTriggerTimeout] = useState(false);
  const [CONFIG, SET_CONFIG] = useState({
    SHOW_BACKGROUND: false
  })

  useEffect(() => {
    if (window.ethereum || window.web3) {
      setHasWeb3Provider(true);
    } else {
      setHasWeb3Provider(false);
    }
  }, []);

  const connectWallet = async () => {
    try {
      const accounts = await web3.eth.requestAccounts();
      if (accounts.length === 0) return;
  
      setAccount(accounts[0]);
      setIsConnected(true);
  
      // Listen for account changes - Add this line
      window.ethereum.on('accountsChanged', handleAccountsChanged);
  
      const proNFTBalance = await proNFT.methods.balanceOf(accounts[0]).call();
      const vipNFTBalance = await vipNFT.methods.balanceOf(accounts[0]).call();
  
      if (proNFTBalance > 0 || vipNFTBalance > 0) {
          setOwnsNFT(true);
  
          const ticketCost = await dudesRaffle.methods.getTicketPrice().call();
          setTicketPrice(web3.utils.fromWei(ticketCost, 'ether'));
  
          const tickets = await dudesRaffle.methods.ticketsBoughtThisRaffle().call();
          setTicketsForThisRaffle(tickets);
      }
    } catch (error) {
      console.error("Error connecting wallet: ", error);
      handleError(error); // Call your error handler
    }
  };
  
  
  const getMaxTicketsForUser = useCallback(async () => {
    if (!addressInput) {
      showNotification('Παρακαλούμε εισάγετε μια διεύθυνση Ethereum.');
      return;
    }

    try {
      const maxTicketsResponse = await dudesRaffle.methods.getMaxTickets(addressInput).call();
      setMaxTickets(maxTicketsResponse);
    } catch (error) {
      console.error("Failed to get max tickets:", error);
      showNotification('Αυτή η διεύθυνση δεν κατέχει PRO ή VIP NFTs.', 'error');
    }
  }, [addressInput]);


  const getWinners = async () => {
  try {
      const epochWinners = await dudesRaffle.methods.getWinnersForEpoch(epochInput).call();
      setWinners(epochWinners);
  } catch (error) {
      console.error("Error getting winners: ", error);
      handleError(error);
  }
  };

  
  const buyRaffleTickets = async (numTickets) => {
    try {
      const numTicketsInt = parseInt(numTickets, 10);
      if (isNaN(numTicketsInt) || numTicketsInt <= 0) {
        throw new Error("Invalid number of tickets");
      }
  
      const totalCostWei = web3.utils.toWei((ticketPrice * numTicketsInt).toString(), 'ether');
  
      const balanceWei = await web3.eth.getBalance(account);
      const balance = web3.utils.toBN(balanceWei);
      const totalCost = web3.utils.toBN(totalCostWei);
  
      // Check if the user's balance is enough for the ticket cost alone
      if (balance.lt(totalCost)) {
        const shortfallWei = totalCost.sub(balance);
        const shortfallEther = web3.utils.fromWei(shortfallWei, 'ether');
        showNotification(`Ανεπαρκείς πόροι. Πρέπει να προσθέσετε τουλάχιστον ${shortfallEther} ETH στο πορτοφόλι σας.`, 'error');
        return;
      }
  
      // Now estimate gas if the ticket cost is covered
      const gasEstimate = await dudesRaffle.methods.buyTicket(numTicketsInt).estimateGas({ from: account, value: totalCostWei });
      const gasPriceWei = await web3.eth.getGasPrice();
      const totalGasCostWei = web3.utils.toBN(gasPriceWei).mul(web3.utils.toBN(gasEstimate));
      const totalRequiredWei = totalCost.add(totalGasCostWei);
  
      // Check if the user's balance is enough for total cost + gas
      if (balance.lt(totalRequiredWei)) {
        const shortfallWei = totalRequiredWei.sub(balance);
        const shortfallEther = web3.utils.fromWei(shortfallWei, 'ether');
        showNotification(`Ανεπαρκείς πόροι (Ticket + Gas). Πρέπει να προσθέσετε τουλάχιστον ${shortfallEther} ETH στο πορτοφόλι σας.`, 'error');
        return;
      }
  
      // Proceed with the transaction
      await dudesRaffle.methods.buyTicket(numTicketsInt).send({ from: account, value: totalCostWei, gas: gasEstimate });
      showNotification('Επιτυχής αγορά!', 'success');
    } catch (error) {
      console.error("Transaction Error:", error);
      showNotification('Η συναλλαγή απέτυχε. Παρακαλούμε προσπαθήστε ξανά.', 'error');
    }
  };
  
  
  
  
  
  

  
  
  
  const handleAccountsChanged = useCallback((accounts) => {
    if (accounts.length === 0) {
      // MetaMask is locked or the user has disconnected
      console.error('Please connect to MetaMask.');

      // Reset state
      setIsConnected(false);
      setAccount('');
      setOwnsNFT(false);
      setTicketPrice('');
      setTicketsForThisRaffle(0);
      

      // Optionally, reset other states if required.
    } else if (accounts[0] !== account) {
      setAccount(accounts[0]);
      // Do any other necessary logic or setup after account change
    }
  }, [account]);

  
  const handleError = (error) => {
    if (error.code === 4001) {
      // EIP-1193 userRejectedRequest error
      console.log('Please connect to MetaMask.');
    } else {
      console.error(error);
    }
  };
  

  useEffect(() => {
    if (window.ethereum && account) {
        window.ethereum.on('accountsChanged', handleAccountsChanged);

        return () => {
            window.ethereum.removeListener('accountsChanged', handleAccountsChanged);
        };
    }
  }, [account, handleAccountsChanged]);


  const getEmojiByIndex = (index) => {
  switch (index) {
    case 0: return '🥇'; // Gold medal for 1st place
    case 1: return '🥈'; // Silver medal for 2nd place
    case 2: return '🥉'; // Bronze medal for 3rd place
    default: return '';
  }
  };


  const useTimeout = (callback, delay, trigger) => {
  const savedCallback = useRef();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    if (!trigger) return; // Only proceed if trigger is true

    const tick = () => {
      savedCallback.current();
      setTriggerTimeout(false);  // Reset trigger after callback is executed
    };

    if (delay !== null) {
      const id = setTimeout(tick, delay);
      return () => clearTimeout(id);
    }
  }, [delay, trigger]);
  };


  const showNotification = (message, type = 'error') => {
    setNotification({ message, type });
    setTriggerTimeout(true); // Trigger the timeout
  };

  
  // Now use the hook with the new trigger state
  useTimeout(() => setNotification({ message: '', type: '' }), 5000, triggerTimeout);
  

  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  useEffect(() => {
    getConfig();
  }, []);
  

 /* useEffect(() => {
    async function fetchInitialCountdown() {
        try {
            const countdownStr = await dudesRaffle.methods.getCurrentCountdown().call();
            const regex = /(\d+)d(\d+)h(\d+)m(\d+)s/;
            const matches = countdownStr.match(regex);

            setCountdown({
                days: parseInt(matches[1], 10),
                hours: parseInt(matches[2], 10),
                minutes: parseInt(matches[3], 10),
                seconds: parseInt(matches[4], 10),
            });
        } catch (error) {
            console.error("Error fetching countdown: ", error);
        }
    } 

    fetchInitialCountdown();

    const intervalId = setInterval(() => {
        setCountdown(prevState => {
            let { days, hours, minutes, seconds } = prevState;

            if (days === 0 && hours === 0 && minutes === 0 && seconds === 0) {
                setIsCountdownEnded(true);
                clearInterval(intervalId);  // stop the interval
                return prevState;
            }

            seconds -= 1;
            if (seconds < 0) {
                seconds = 59;
                minutes -= 1;
                if (minutes < 0) {
                    minutes = 59;
                    hours -= 1;
                    if (hours < 0) {
                        hours = 23;
                        days -= 1;
                    }
                }
            }

            return { days, hours, minutes, seconds };
        });
    }, 1000);

    return () => {
        clearInterval(intervalId);
    };
}, []); */
                


useEffect(() => {
  async function fetchCurrentEpoch() {      
      try {
          const epoch = await dudesRaffle.methods.currentEpoch().call();
          setCurrentEpoch(epoch);
      } catch (error) {
          console.error("Error fetching current epoch: ", error);
      }
  }
  
  fetchCurrentEpoch();
}, []);

  // Main App Interface


  return (
    <s.Screen>
      <s.Container 
        flex={1} 
        ai={"center"} 
        style={{ padding: 24, backgroundColor: "var(--primary)" }}
        image={CONFIG.SHOW_BACKGROUND ? "config/images/bg.png" : null}>
        <StyledLogo alt={"logo"} src={"/config/images/logo.png"} />
        
        
        
        <s.SpacerSmall />
          <s.Container ai="center">
            {notification.message && (
              <Notification type={notification.type}>
            {notification.message}
              </Notification>
            )}   
          </s.Container>
        
        
        <ResponsiveWrapper flex={1} style={{ padding: 24 }}>


          {/*Place for side pics or gif*/}
          <s.Container flex={1} jc={"center"} ai={"center"}></s.Container>


          <s.SpacerLarge />
          <s.Container
            flex={2}
            jc={"center"}
            ai={"center"}
            style={{
              backgroundColor: "var(--accent)",
              padding: 24,
              borderRadius: 24,
              boxShadow: "0px 5px 11px 2px rgba(0,0,0,0.7)",
            }}>
              {hasWeb3Provider === false && (
          <div style={{ textAlign: "center", marginBottom: "20px" }}>
            <s.TextDescription style={{ color: "red" }}>
            Δεν εντοπίστηκε πάροχος web3.<br></br>Παρακαλούμε εγκαταστήστε το Metamask και προσπαθήστε ξανά.
            </s.TextDescription>
          </div>
        )}
            {isConnected && ownsNFT ? (
            <>
            
            
            <s.Container flex={1} direction="column" ai="center">


                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: "30px" }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                    
                      <div style={{ marginRight: '20px' }}>
                      <s.TextTitle style={{ fontSize: 24, fontWeight: "bold", color: "var(--accent-text)" }}>
                      Τιμή Εισιτηρίου: {ticketPrice} ETH
                      </s.TextTitle>
                      <s.TextDescription style={{ color: "var(--secondary-text)" }}>
                      Σύνολο συμμετοχών έως τώρα: {ticketsForThisRaffle}
                      </s.TextDescription>
                      </div>

                      <div>
                        <StyledButton2 onClick={() => setNumTicketsToBuy(prev => Math.max(1, prev - 1))}>-</StyledButton2>
                        <TicketsInput 
                        type="number" 
                        value={numTicketsToBuy} 
                        onChange={e => setNumTicketsToBuy(Math.max(1, e.target.value))}
                        min="1" 
                        placeholder="Number of tickets"
                        style={{ marginRight: '5px', marginLeft: '5px' }}/>
                        <StyledButton2 onClick={() => setNumTicketsToBuy(prev => Math.max(1, prev + 1))} style={{ marginRight: '20px', marginBottom: '5px' }}>+</StyledButton2>
                        <StyledButton onClick={() => buyRaffleTickets(numTicketsToBuy)}>Συμμετοχή</StyledButton>
                      </div>
                    </div>
                    <s.SpacerSmall/>
                </div>

      

          
                <s.TextTitle style={{ textAlign: "center", fontSize: 24, fontWeight: "bold", color: "var(--accent-text)", paddingTop: "30px"}}>Έλεγξε πόσα εισιτήρια δικαιούσαι</s.TextTitle>
                <EthereumAddressInput type="text" value={addressInput} onChange={e => setAddressInput(e.target.value)} placeholder="0xF9000000000000000000000000003N29" />
                <s.SpacerSmall />
                <StyledButton onClick={getMaxTicketsForUser}>Check</StyledButton>
                <s.SpacerSmall />
                  {maxTickets > 0 &&
                  <s.TextDescription style={{ textAlign: "center", color: "var(--accent-text)" }}>Welcome METADUDE! You can buy up to {maxTickets} tickets 🥳 !</s.TextDescription>}
            </s.Container>

          
                  <s.SpacerSmall/>
            <s.Container flex={1} direction="column" ai="center">
                  <s.TextTitle style={{ textAlign: "center", fontSize: 24, fontWeight: "bold", color: "var(--accent-text)", paddingTop: "30px" }}>Νικητές προηγούμενων κληρώσεων</s.TextTitle>
                 

                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <StyledButton2 onClick={() => setEpochInput(prev => Math.max(1, prev - 1))}>-</StyledButton2>
                    <EpochNumberInput 
                        type="number"
                        value={epochInput}
                        onChange={e => setEpochInput(Math.max(1, e.target.value))}
                        min="1"
                        placeholder="Number of tickets"
                        style={{ marginRight: '5px', marginLeft: '5px'}} // Added textAlign to center the input text
                    />
                    <StyledButton2 onClick={() => setEpochInput(prev => Math.max(1, prev + 1))} style={{ marginRight: '20px' }}>+</StyledButton2>
                    <StyledButton onClick={getWinners}>Check</StyledButton>
                  </div>

                
                  {winners.length > 0 &&
                    <s.TextDescription style={{ textAlign: "center", color: "var(--accent-text)" }}>Winners:
                      {winners.map((winner, index) => (
                      <span key={index} style={{ display: 'block', margin: '10px 0' }}>
                      {getEmojiByIndex(index)} {winner}
                      </span>
                    ))}
                    </s.TextDescription>
                  }

                  <div>
                      <h1>
                      <s.TextTitle style={{ textAlign: "center", fontSize: 24, fontWeight: "bold", color: "var(--accent-text)", paddingTop: "30px" }}>Τωρινή Season: {currentEpoch}</s.TextTitle>
                      </h1>
                  </div>


                  <div>
                      
                      <s.TextTitle style={{ textAlign: "center", fontSize: 14, fontWeight: "light", color: "var(--accent-text)", paddingTop: "5px" }}>Η κλήρωση θα γίνεται κάθε μήνα live<br></br>την ώρα του Metadudes Monthly Report.</s.TextTitle>
                      
                  </div>

                <s.SpacerSmall/>
            </s.Container></>
                

                ) : isConnected && !ownsNFT ? (
                  <div style={{ textAlign: "center" }}>
                  <s.TextDescription style={{ textAlign: "center", color: "var(--accent-text)" }}>
                    Δεν είστε κάτοχος PRO ή VIP NFT. Παρακαλούμε αποκτήστε ένα για να συμμετάσχετε.
                  </s.TextDescription>
                  <s.SpacerSmall/>
                  <StyledButton style={{padding: "15px", width: "150px", fontSize: "18px"}}
                    onClick={() => window.location.href='https://metadudes.gr/mint.html'}
                  >
                    Mint Yours
                    </StyledButton>
                </div>
                ) : (
                <StyledButton style={{padding: "15px", width: "150px", fontSize: "18px"}} onClick={connectWallet}>Σύνδεση</StyledButton>
                )}

          
            <s.SpacerSmall/>
          </s.Container>
          
          <s.SpacerLarge/>

          {/*Place for side pics or gif*/}
          <s.Container flex={1} jc={"center"} ai={"center"}></s.Container>


        </ResponsiveWrapper>
        
        
        {/* Main App Interface Ends */}


        <s.SpacerMedium />
        <s.Container jc={"center"} ai={"center"} style={{ width: "70%" }}>
          {/* Any additional information, warnings, or notes you'd like to add can go here using <s.TextDescription> elements. */}
        </s.Container>

        
      </s.Container>
    </s.Screen>
  );
}

export default App;